import React from "react";
import { Link } from 'gatsby'
import { Jumbotron } from "../components/Jumbotron";
import { Row, Col, Modal, Button  } from "react-bootstrap";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";
import "../assets/css/Section.css";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import CallBackForm from "../components/CallBackForm";
import boiler from "../../static/Icons/boiler.png";
import pipe from "../../static/Icons/pipe.png";
import spanner from "../assets/Icons/spanner.png";
import certificate from "../assets/Icons/certificate.png";
import { Brands } from "../components/Branding";
import { RE } from "../components/Reviews";
import ContactForm from "../components/ContactForm";


const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        bbisHero4: file(relativePath: { eq: "Hero/bbis-hero-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.bbisHero4.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

export default () => (
  <Layout>
    <SEO
      title="Commercial Boiler Services in London"
      description="At BBIS Heating, our commercial boiler services will keep your premises in London working smoothly. Contact us today for a free quote"
      keywords=""
    />
    <BackgroundSection>
      <Jumbotron>
        <ul className="pt-4 jumbo-list">
          <h1 class="jumbo-heading">Commercial Boiler Repair, Installation and Servicing in London</h1>
        </ul>
        <Modal backdrop="static">
        <CallBackForm />
      </Modal>
      </Jumbotron>
    </BackgroundSection>
    <div class="white-segment">
      <h2 class="heading-color pb-2">We repair, service and install a wide range of electric, gas, LPG and oil boilers</h2>
      <h3 className="heading-color">Why choose BBIS Commercial Heating?</h3>
      <ul>
        <li>Same or next working day repair service</li>
        <li>5-star ratings and reviews</li>
        <li>50 years of experience in domestic and commercial heating</li>
        <li>Friendly local commercial boiler engineers in London</li>
      </ul>
      <h3></h3>
      <p>It’s vital to service your commercial boiler regularly to keep it running safely and efficiently. Have your London commercial boiler serviced before winter so we can highlight and fix any potential problems before it’s too late.</p>
      <Row>
        <Col md className="home-button">
          <Link to="/commercial-boiler-servicing/">
            <img alt="Image of boiler" class="pb-4 mx-auto d-block home-icon" src={boiler} />
            <h3 class="heading-color text-center">Commercial boiler servicing</h3>
            <p class="text-center normal-text-colour">
            It’s vital to service your commercial boiler regularly to keep it running safely and efficiently. Have your London commercial boiler serviced before winter so we can highlight and fix any potential problems before it’s too late.
            </p>
          </Link>
        </Col>
        <Col md className="home-button">
          <Link to="/commercial-boiler-repair/" className="home-icon">
            <img alt="Image of spanner" class="pb-4 mx-auto d-block home-icon" src={spanner} />
            <h3 class="heading-color text-center">Commercial boiler repair</h3>
            <p class="text-center normal-text-colour">We understand the inconvenience of your premises being without heating or hot water, and how it will affect the smooth running of your business. We always aim to get to you the same or the next working day to assess your commercial boiler.</p>
          </Link>
        </Col>
      </Row>
      <Row>
      <Col md className="home-button">
          <Link to="/commercial-boiler-installations/">
            <img alt="Image of pipes" class="pb-4 mx-auto d-block home-icon" src={pipe} />
            <h3 class="heading-color text-center">Commercial boiler installation</h3>
            <p class="text-center normal-text-colour">
            Is your commercial boiler old and inefficient? It could cost your business money as it must work harder to run. If you’re having regular breakdowns that need repairs it might be time for a new boiler. We can guide you through the process and help you find the best commercial boiler for your London premises. 
            </p>
          </Link>
        </Col>
      <Col md className="home-button">
          <Link to="/commercial-gas-safety-check/" className="home-icon">
            <img alt="Image of spanner" class="pb-4 mx-auto d-block home-icon" src={certificate} />
            <h3 class="heading-color text-center">Gas Safety certificates</h3>
            <p class="text-center normal-text-colour">Whether you’re a landlord with properties in London or you have commercial premises, we’ll carry out a Gas Safety check and issue you with a Gas Safety certificate. Keep your tenants, staff and customers safe by having a Gas Safe registered engineer check that your commercial heating systems are working correctly.</p>
          </Link>
        </Col>
      </Row>
      <Brands />

      <p>For professional commercial boiler services in London, call us today on 0800 2289800 or request a call back by using our form below.</p>

    </div>
    <Row>
      <div className="grey-segment">
        <h3 className="heading-color">Recent commercial boiler projects in London</h3>
        <h4>Wimpole Street, London</h4>
        <p>In this property, the old boiler room occupied two rooms and was designed and built in the 1980s, so was in need of modernisation. We removed the plant and designed a new boiler room that took up half of the original space, allowing the other half to be turned into a reception area and washroom facilities.</p>
        <p>We installed 3 11kW Remeha boilers to provide the heating, and hot water was stored in 2 large calorifiers. We fabricated all pipework and flues on site and fitted a bespoke control panel to run the boilers, pumps and valves via a TREND BMS system.</p>
        <p>Photos</p>
        <h4 className="heading-color">How often should commercial boilers be serviced?</h4>
        <p>It’s recommended that you have your commercial boiler serviced <strong>at least once a year.</strong> This way, problems are picked up early, reducing the impact on your heating services.</p>
        <h4 className="heading-color">Who is responsible for the boiler in a commercial property?</h4>
        <p>Who is responsible for the boiler in a commercial property depends on a number of factors. If you are a <strong>tenant</strong> in your premises, you should <strong>check your contract</strong> to see who is responsible. Usually, your landlord will be responsible for maintaining your heating system If you <strong>own</strong> your London commercial property, <strong>you’ll be responsible</strong> for the boiler!</p>
        <h4 className="heading-color">How long does it take to install a commercial boiler?</h4>
        <p>How long it takes to install a commercial boiler depends on the system. A straightforward installation could take <strong>a day</strong>, while larger, more complex heating systems could take <strong>a week or more.</strong> We’ll always let you know how long we expect your commercial boiler installation to take.</p>
        </div>
        <div className="white-segment">
      <RE></RE>
      <h3 className='heading-color'>Contact BBIS Heating</h3>
      <ContactForm></ContactForm>
      </div>
    </Row>

  </Layout>

);
